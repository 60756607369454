import React from 'react';
import { Image, Typography } from 'tfc-components';

import backgroundMB from 'assets/images/background-mb.jpg';
import background from 'assets/images/background.jpg';
import button from 'assets/images/button.png';
import logo from 'assets/images/logo.png';
import slogan from 'assets/images/slogan.png';
import title from 'assets/images/title.png';
import useWindowDimensions from 'hooks/useWindowDemensions';

const Home: React.FC = () => {
  const { width, height } = useWindowDimensions();
  return (
    <div className="p-home" style={{ minHeight: `${height}px` }}>
      <Image extendClasses="p-home_background" imgSrc={width > 576 ? background : backgroundMB} alt="Maggi" ratio={width > 576 ? 16 / 9 : 1 / 2} />
      <div className="p-home_content">
        <div className="p-home_logo">
          <Image imgSrc={logo} alt="Maggi logo" ratio={7 / 5} />
        </div>
        <div className="p-home_title">
          <Image imgSrc={title} alt="Maggi" ratio={765 / 91} />
        </div>
        <div className="p-home_text">
          <Typography.Text extendClasses="text-29x46">Thời gian diễn ra livestream:</Typography.Text>
        </div>
        <div className="p-home_time">
          <Typography.Text extendClasses="p-home_time_text text-38x60" fontweight="700">9:30 - 11:30,</Typography.Text>
          <Typography.Text extendClasses="p-home_time_text text-36x56">Thứ ba ngày</Typography.Text>
          <Typography.Text extendClasses="p-home_time_text text-38x60" fontweight="700">14/11/2023</Typography.Text>
        </div>
        <div className="p-home_button">
          <a href="https://youtube.com/live/a_JCoTDb6Bg?feature=share">
            <Image imgSrc={button} alt="Maggi" ratio={449 / 51} />
          </a>
        </div>
        <div className="p-home_slogan">
          <Image imgSrc={slogan} alt="Maggi" ratio={732 / 285} />
        </div>
      </div>
    </div>
  );
};

export default Home;
