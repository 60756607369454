import './App.scss';
import React from 'react';

import Home from 'pages/Home';

const App: React.FC = () => (
  <Home />
);

export default App;
